import { ArrowForward, HomeRounded } from '@mui/icons-material';
import { Divider } from '@sortlist-frontend/design-system';
import { TFunction, Trans } from '@sortlist-frontend/translation/server-only';
import { Fragment } from 'react';

import { DomainInfo } from '_backend/integration/domain-info';
import { NavigationData } from '_components/layout/Layout';
import { Sort } from '_features/longtail/components/Sort/Sort';
import { LinkDetails } from '_features/longtail/utils';

type Props = {
  appRouter: boolean;
  className?: string;
  breadcrumbs: LinkDetails[];
  textColor?: string;
  hideLinks?: boolean;
  hideProviders?: boolean;
  totalAgenciesCount: number;
  navigationData: NavigationData;
  t: TFunction;
  modifier?: string;
};

const persistFilters = (query?: string) => {
  let newSearchParams = null;

  const searchParams = new URLSearchParams(query);
  const filters = searchParams.get('filters');

  if (filters != null) {
    const queryParams = new URLSearchParams();
    queryParams.set('filters', filters);
    newSearchParams = queryParams.toString(); // keep filters only as query params
  }

  return newSearchParams != null ? `?${newSearchParams}` : '';
};

export const Breadcrumbs = (props: Props) => {
  const {
    appRouter,
    breadcrumbs,
    className,
    hideLinks,
    hideProviders,
    textColor = 'text-secondary-500',
    totalAgenciesCount,
    navigationData,
    modifier,
    t,
  } = props;
  const { origin, locale, query } = navigationData;

  const queryString = persistFilters(query);
  const domainInfo = DomainInfo.getFromOrigin(origin);

  return (
    <Fragment>
      <div
        className={`${className ?? ''} layout-row layout-align-gt-sm-space-start-start layout-align-space-between-center`}>
        {!hideLinks ? (
          <div className="flex layout-row layout-align-start-center layout-wrap">
            {/* There is a but/feature in next/Link that prefixes sortlist.com => sortlist.com/ (only a problem for homepage links)
          https://github.com/vercel/next.js/issues/19922 => hydration error
          <Link href={domainInfo?.getLocalizedUrl(locale) || ''}>
            <a className="small text-primary-500 text-underline pt-4">{t('longtail:breadcrumbs.home')} </a>
          </Link> */}

            <a
              href={domainInfo?.getLocalizedUrl(locale) ?? 'https://www.sortlist.com'}
              className={`${textColor} layout-row layout-align-start-center `}
              aria-label="home link">
              <HomeRounded style={{ fontSize: 14 }} />
            </a>

            {breadcrumbs.length > 0 ? (
              <ArrowForward className="text-secondary-300 mx-4" style={{ fontSize: 16 }} />
            ) : null}

            {breadcrumbs.map((breadcrumb, key) => {
              const isLast = key === breadcrumbs.length - 1;
              return (
                <Fragment key={key}>
                  <a
                    href={`${breadcrumb.url}${queryString}`}
                    className={`${isLast ? textColor : 'text-secondary-900'} medium small`}>
                    {breadcrumb.anchor}
                  </a>
                  {!isLast && <ArrowForward className="text-secondary-300 mx-4" style={{ fontSize: 16 }} />}
                </Fragment>
              );
            })}
          </div>
        ) : null}
        {!hideProviders ? (
          <div className="text-nowrap mt-gt-sm-0 layout-row layout-align-start-center gap-x-16">
            <span className={`${textColor} small`}>
              {modifier != null ? (
                <Fragment>
                  <span className={'text-secondary-900 medium'}>{totalAgenciesCount}</span>&nbsp;{modifier}
                </Fragment>
              ) : (
                <Trans i18nKey="longtail:agencies.count" count={totalAgenciesCount} t={t} appRouter={appRouter}>
                  <span className={'text-secondary-900 medium'}>{{ count: totalAgenciesCount }}</span>
                  providers
                </Trans>
              )}
            </span>
            <div className="hide-xs">
              <Sort navigationData={navigationData} t={t} />
            </div>
          </div>
        ) : null}
      </div>
      <Divider className="hide-gt-xs" />
    </Fragment>
  );
};
